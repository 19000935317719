.navigation {
  @apply flex flex-col gap-1 mb-auto;
}

.navItem {
  @apply text-swarm-gray-700
    cursor-pointer
    font-semibold
    text-base
    rounded
    border
    border-transparent
    leading-6
    transition-all;
  padding: 7px;
  &Inner {
    @apply flex gap-2 items-center;
  }
  &:hover {
    @apply bg-swarm-gray-200
      border-swarm-gray-500
      text-swarm-gray-800;
  }
  &Active,
  &Active:hover {
    @apply bg-swarm-gray-50
      border-swarm-gray-500
      text-swarm-black
    shadow-swarm-lg-soft;
  }
  &IconOnly {
    @apply w-10 overflow-hidden;
  }

  &Locked {
    @apply cursor-not-allowed;
  }
}

.icon {
  @apply w-6 h-6 flex items-center justify-around shrink-0;

  &Right {
    @apply ml-auto;
  }
  &Remove {
    @apply hover:text-swarm-black opacity-0;
  }
}

.navItem:hover .iconRemove {
  @apply opacity-100;
}
