:root {
  --ring-color: #ababab;
  --indicator-color: #4a6aef;
}

.spinnerSVG {
  &.centered {
    @apply m-auto mt-16;
  }
  // g/g[2]/g/path
  g {
    g:nth-child(2) {
      g {
        path {
          stroke: #{var(--indicator-color)};
        }
      }
    }
  }

  // g/g[1]/g/path
  g {
    g:nth-child(1) {
      g {
        path {
          stroke: #{var(--ring-color)};
        }
      }
    }
  }
}

.colorBlack {
  --indicator-color: #000;
}

.colorWhite {
  --indicator-color: #fff;
}

.colorRed {
  --indicator-color: #cf2a13;
}

.colorGreen {
  --indicator-color: #327d4f;
}
