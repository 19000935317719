@font-face {
  font-family: 'Roobert PRO';
  src: url('RoobertPRO-Regular.woff2') format('woff2'), url('RoobertPRO-Regular.woff') format('woff'),
    url('RoobertPRO-Regular.otf') format('otf'), url('RoobertPRO-Regular.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert PRO';
  src: url('RoobertPRO-Medium.woff2') format('woff2'), url('RoobertPRO-Medium.woff') format('woff'),
    url('RoobertPRO-Medium.otf') format('otf'), url('RoobertPRO-Medium.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert PRO';
  src: url('RoobertPRO-SemiBold.woff2') format('woff2'), url('RoobertPRO-SemiBold.woff') format('woff'),
    url('RoobertPRO-SemiBold.otf') format('otf'), url('RoobertPRO-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert PRO';
  src: url('RoobertPRO-Bold.woff2') format('woff2'), url('RoobertPRO-Bold.woff') format('woff'),
    url('RoobertPRO-Bold.otf') format('otf'), url('RoobertPRO-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
