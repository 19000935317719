.pipelineTag {
  @apply h-10 mb-2 bg-swarm-gray-100 flex rounded border border-swarm-gray-400;
}

.pipelineLinkWrapper {
  @apply flex mr-auto flex-grow truncate shrink items-center ml-2 pr-1;
  &:hover {
    @apply underline;
  }
}

.pipelineLink {
  @apply text-base text-swarm-gray-900 font-light leading-normal;
}

.statusSwitch {
  @apply h-full bg-white border-l border-swarm-gray-400 mr-0 ml-1 pr-1 rounded-r shrink-0;
  padding-top: 7px;
  width: 150px;
}

.removeFromPipelineButtonWrapper {
  @apply h-full flex items-center bg-white w-10 flex rounded-r shrink-0 justify-center
  border-l border-swarm-gray-400 overflow-hidden;
}
