.selectWrapper {
  @apply relative 
    mx-2 
    inline-block;
}

.select {
  @apply h-6
    cursor-pointer
    rounded-none
    border-x-0 border-t-0 border-b
    border-swarm-gray-500 
    bg-none py-0 pr-7 pl-3;
}

.select:focus {
  @apply rounded
    border-none
    ring-2 ring-swarm-blue;
}

.selectError {
  @apply border-swarm-red hover:border-swarm-red focus:border-none;
}

.selectIcon {
  @apply pointer-events-none 
    absolute inset-y-0 
    right-1
    h-full;
}

.selectIcon > polyline {
  @apply stroke-swarm-gray-500;
}

.select:hover ~ .selectIcon > polyline {
  @apply stroke-swarm-gray-700;
}

.selectError ~ .selectIcon > polyline,
.selectError:hover ~ .selectIcon > polyline {
  @apply stroke-swarm-red;
}

.select:focus ~ .selectIcon > polyline {
  @apply stroke-swarm-gray-700;
}
