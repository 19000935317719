.accountSettings {
  @apply flex gap-4;
}

.formWrapper {
  @apply flex-1;
}

.editUser {
  @apply flex gap-4 mb-2;
  &Field {
    @apply flex-1;
  }
}

.emailInput {
  @apply bg-swarm-gray-600;
}

.errorMessage {
  @apply mt-0;
}

.footer {
  @apply flex justify-end bg-swarm-gray-50 py-4 px-4 -m-4 mt-2 border-t border-swarm-gray-200;
}

.saveButton {
  @apply text-white bg-swarm-gray-800;
}

.settingsActionBoxWrapper {
  @apply flex gap-4 justify-between items-center;
}

.settingsActionBoxInfo {
  @apply text-swarm-gray-700;
}
