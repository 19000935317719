.background {
  @apply h-screen flex flex-col bg-swarm-gray-100 relative;
}

.topbar {
  @apply h-16 flex items-center px-5 static;
}

.logo {
  @apply w-32 h-8;
}

.wrapper {
  @apply flex flex-col w-full mx-auto grow items-center justify-center p-2.5 sm:p-0;
}

.footer {
  @apply py-6 text-sm text-swarm-gray-600 text-center;
}
