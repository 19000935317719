.container {
  @apply flex h-screen w-screen overflow-hidden h-full max-h-full;
  min-width: 860px;
}

.sidebar {
  @apply flex-none bg-swarm-gray-100 h-screen max-h-full;
  width: var(--sidebar-width);
  transition: width 0.2s ease-in-out;
}

.contentWrapper {
  @apply relative flex grow h-screen;
}

.contentWrapperInner {
  @apply absolute inset-0 flex flex-col;
}

.content {
  @apply relative flex-1 overflow-auto pl-2;

  &NoGrow {
    @apply grow-0 basis-auto pb-0.5;
  }
}

.header {
  @apply flex flex-none;
  height: var(--header-height);
}

.subHeader {
  @apply pl-2;
}

.footer {
  @apply flex-none;
  min-height: var(--footer-height);
}
