.wrapper {
  @apply my-6;
}

.header {
  @apply flex items-center gap-2 mb-4 text-xl font-semibold text-swarm-black;
}

.content {
  @apply grid grid-cols-3 gap-4;
}
