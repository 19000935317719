.pageHeader {
  @apply flex w-full flex-row place-content-between items-center bg-white py-2 pl-4;
}

.borderedPageHeader {
  @apply border-b;
}

.grayPageHeader {
  @apply bg-swarm-gray-100 relative;
  &:before {
    content: '';
    @apply absolute -bottom-2 left-0 size-2 bg-swarm-gray-100;
  }
  &:after {
    content: '';
    @apply absolute -bottom-4 left-0 size-4 bg-white rounded rounded-full;
  }
}

.leftSlot {
  @apply flex items-center gap-2 grow;
}

.title {
  @apply text-2xl font-semibold capitalize;
}

.childrenWrapper {
  @apply flex grow;
}

.rightSlot {
  @apply ml-4 mr-2 flex gap-2;
}
