.textarea {
  @apply block w-full relative z-10;
}

.info {
  @apply relative -mt-2 bg-swarm-gray-100 p-4 pt-6;

  &Title {
    @apply text-lg text-swarm-black flex flex-row items-center gap-2 font-semibold mb-2;
  }
}

.message {
  @apply text-sm text-gray-500;
  @apply flex flex-row items-center gap-1;

  &Success {
    @apply text-swarm-green;
  }

  &Fail {
    @apply text-swarm-red;
  }
}
