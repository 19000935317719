.fieldRow {
  @apply flex leading-normal mb-5;
  .labelArea {
    @apply w-1/5 pt-2 text-swarm-black;
    .label {
      @apply font-semibold;
    }
  }
  .fieldArea {
    @apply w-4/5;
    & > * {
      @apply my-0;
    }
    label {
      @apply leading-normal inline-block py-2;
    }
  }
}
