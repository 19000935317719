.addNoteButton {
  @apply ml-auto;
}

.addNoteModal {
  @apply font-light text-swarm-gray-700;
  width: 640px;
  &Header {
    @apply text-3xl font-bold text-swarm-black;
  }
  &Subheader {
    @apply mt-2 mb-4 leading-8;
  }
  &Buttons {
    @apply mt-4 gap-2 flex;
  }
}
