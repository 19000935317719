.subscriptionWidget {
  @apply flex flex-col gap-2;
}

.trial {
  @apply flex gap-2 items-center text-base px-1 pt-2 whitespace-nowrap min-w-fit;
}

.planName {
  @apply flex items-center uppercase text-swarm-gray-700 text-xs font-semibold whitespace-nowrap;
}

.freePlanIcon {
  @apply text-base ml-0.5 mr-2;
}

.paidPlanIcon {
  @apply w-10;
}

.planIconCollapsed {
  @apply mb-2 w-10 overflow-hidden text-center cursor-default;
  .freePlanIcon {
    @apply ml-0;
  }
}

.trialEnded {
  @apply flex text-sm whitespace-nowrap;
}

.freeTrialEndedIcon {
  @apply mb-1 w-10 overflow-hidden text-center cursor-default block;
}

.upgradeButton,
.subscriptionButton {
  @apply whitespace-nowrap;
  &Collapsed {
    @apply w-8 ml-1 px-0 text-center;
  }
}
