.wrapper {
  @apply w-full space-y-2;
}

.header {
  @apply flex items-center gap-1;
}

.title {
  @apply font-bold;
}
