.favButton {
  @apply inline-flex cursor-pointer;
}

.starHolder {
  @apply relative size-3.5;
}

.star {
  @apply absolute inset-0 opacity-0 transition-opacity duration-200;
}

.visible {
  @apply opacity-100;
}
