.progressBarWrapper {
  @apply w-full
    rounded;
}

.progressBar {
  @apply block
    h-full
    rounded;
}

.progressBarWarning {
  @apply border-swarm-orange-darken
    bg-swarm-orange;
}

.progressBarError {
  @apply border-swarm-red-dark
    bg-swarm-red;
}

.circular {
  @apply rounded rounded-full text-sm text-center whitespace-nowrap flex items-center justify-center;
}
