.planDetails {
  @apply border border-swarm-gray-400 rounded flex-1 flex flex-col;
  &Horizontal {
    @apply flex-row;
  }
}

.planHeader {
  @apply p-4 border-b border-swarm-gray-400 h-36 flex flex-col;
  &Premium {
    @apply bg-swarm-gray-300;
  }
  &Horizontal {
    @apply h-auto w-1/4 block bg-swarm-gray-300;
  }
}

.planName {
  @apply text-2xl font-medium font-semibold;
}

.description {
  @apply mb-4 mt-2;
}

.planPrice {
  @apply text-3xl font-bold pt-1 h-10;
}

.paymentPeriod {
  @apply mt-0.5;
}

.specialLabel {
  @apply bg-swarm-yellow-pale mt-auto border-y border-swarm-yellow-darken -mx-4 -mb-4 uppercase text-center text-xs py-1 font-semibold;
}

.detailsContent {
  @apply p-4 flex flex-col flex-1;
  min-height: 440px;
}

.features {
  @apply flex-1 pt-4 pb-2;
}

.featuresTitle {
  @apply font-semibold pb-2;
}
