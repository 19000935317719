.statusTag {
  .tagPill {
    @apply w-28 h-6 justify-center text-sm whitespace-nowrap;
  }
  &.small {
    .tagPill {
      @apply w-24 text-xs h-5;
    }
  }
}
