.inputMessage {
  @apply min-h-[20px]
    text-sm
    text-swarm-gray-700;
}

.inputMessageError {
  @apply text-swarm-red;
}

.inputMessageSuccess {
  @apply text-swarm-green-darken;
}
