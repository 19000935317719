.header {
  @apply border-b border-swarm-gray-200 p-4 flex justify-end items-center gap-2 -mt-4 -mx-4;
}

.heading {
  @apply text-xl font-bold mr-auto;
}

.creationCell {
  @apply flex flex-col items-start;
}

.time,
.count {
  @apply text-swarm-black;
}

.creator {
  @apply text-swarm-gray-700;
}

.nameCell {
  @apply flex gap-1;
}

.name {
  @apply mr-auto;
}
