.linksWrapper {
  @apply my-1 flex flex-col gap-0.5;
}

.link {
  @apply text-swarm-gray-700
  cursor-pointer
  flex
  gap-2
  font-semibold
  text-base
  leading-6;
  padding: 7px;
  &:hover {
    @apply text-swarm-gray-800;
  }
}

.icon {
  @apply w-6 h-6 flex items-center justify-around shrink-0;
}
