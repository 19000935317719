.divider {
  @apply my-1 -mx-1;
}

.userLabel {
  @apply rounded flex
  flex-row w-full
  items-center max-w-full gap-2;
  &Header {
    @apply pb-2;
  }
}

.menuHeader {
  @apply p-3 w-full;
}

.userDetails {
  @apply flex-shrink overflow-hidden;
}

.userEmail {
  @apply text-swarm-gray-500 text-xs;
}
