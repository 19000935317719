.pageWrapper {
  @apply mx-auto
    flex flex-col
    items-center
    py-3;
}

.upgrade {
  @apply max-w-4xl;
}

.header {
  @apply text-center;
}

.heading {
  @apply text-3xl
  font-bold
  mb-1
  pt-6;
}

.subheading {
  @apply text-swarm-gray-700;
}
