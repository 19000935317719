.pipelineSuggestions {
  @apply font-semibold pr-10;
}

.suggestionHeader {
  @apply flex items-center pb-2;
  img {
    cursor: default;
    @apply mr-2;
  }
}
