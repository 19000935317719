.addMembersModal {
  width: 580px;
}

.header {
  @apply flex gap-2 text-xl font-bold -mt-2;
}

.subheader {
  @apply text-base text-swarm-gray-700 mt-1;
}

.headerIcon {
  @apply block w-6 h-6;
}

.formContent {
  @apply my-4;
}

.divider {
  @apply -mx-8 mb-0 mt-6;
}

.roleSelector {
  @apply py-4;

  &Item {
    @apply p-4 flex flex-row items-center gap-4 transition;

    &:hover {
      @apply bg-swarm-gray-100 cursor-pointer;
    }

    &Title {
      @apply text-lg font-semibold flex items-center gap-2;
    }

    &Description {
      @apply text-sm text-swarm-gray-700;
    }
  }
}

.permissionsHeader {
  @apply font-semibold;
}

.permissionsList {
  @apply my-1 ml-2 list-disc list-inside;
}

.permissionsLearnMoreText {
  @apply text-swarm-gray-700;
}

.illustration,
.paragraph {
  @apply mb-4;
}

.footer {
  @apply flex items-center gap-4 pt-4;
}

.learnMore {
  @apply text-swarm-gray-700 text-sm;
}
