.addToPipelineButton {
  @apply cursor-pointer transition-all;
  &Open {
    @apply text-black;
  }
}

.disabled {
  @apply cursor-not-allowed;
}
