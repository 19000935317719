.list {
  @apply list-decimal space-y-2 pl-5 mb-5 text-base font-normal;

  em {
    @apply text-swarm-black font-normal not-italic leading-relaxed;
  }
}

.link {
  @apply text-swarm-black underline;
}
