.blueRadio,
.blackRadio {
  @apply h-4 w-4 border border-swarm-gray-400;
}

.blueRadio:focus,
.blackRadio:focus {
  @apply ring-[3px] ring-swarm-blue ring-offset-1;
}

.blueRadio:checked {
  @apply border-swarm-blue-darken text-swarm-blue;
}

.blueRadio:checked:focus {
  @apply ring-[3px] ring-swarm-blue ring-offset-1;
}

.blueRadio:checked:hover {
  @apply border-4 border-swarm-blue-darken text-transparent;
}

.blackRadio:checked {
  @apply border-swarm-black text-swarm-black;
}

.blackRadio:checked:hover {
  @apply border-4 border-swarm-black text-transparent;
}

.radioButtonsWrapper {
  @apply flex divide-x divide-gray-400 rounded border border-solid border-swarm-gray-400;
}

.radioButtonsWrapperInvalid {
  @apply border-swarm-red;
}

.radioButtonWrapper {
  @apply p-2.5 pr-3.5 flex-1;
}
.radioButtonWrapperLabel {
  @apply flex;
  input {
    @apply mt-1;
    &:focus {
      @apply ring-0;
    }
  }
}

.radioButtonLabel {
  @apply ml-2.5 text-swarm-gray-800;
}

.radioGroupStacked {
  @apply flex flex-col;
}

.radioGroupInline {
  @apply flex flex-row items-center;
}
