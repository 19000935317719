.note {
  @apply rounded my-4 bg-swarm-gray-100 p-3;
  max-width: 480px;
  &Private {
    @apply bg-swarm-yellow-light;
  }
}

.noteHeader {
  @apply flex items-center gap-2;
}

.noteAuthor {
  @apply flex items-center gap-2 whitespace-nowrap;
}

.noteDate {
  @apply text-sm text-swarm-gray-500 whitespace-nowrap;
}

.privacyIcon {
  @apply w-4;
}

.noteContent {
  @apply pt-2 whitespace-pre-wrap;
}

.noteMenu {
  @apply ml-auto;
}

.deleteButton {
  @apply text-swarm-red;
}
