.profileWidthContainer {
  @apply grid min-w-[960px] max-w-7xl grid-cols-3;
}

.profileContentBlock {
  @apply pt-6;
}

.profileColumn {
  @apply px-4 py-2;
  &:first-child {
    @apply pl-2;
  }
}

.crmDataColumn {
  @apply col-span-2;

  a {
    @apply underline;
  }
}

.sectionHeader {
  @apply text-xl font-bold py-2;
}
