.onRowHoverVisible {
  visibility: hidden;
}
.onRowHoverVisibleIgnore {
  visibility: initial !important;
}

.tableBody {
  @apply w-full;
  @apply divide-y divide-swarm-gray-300;
}

.tableRow {
  @apply relative flex h-12 w-full flex-row transition duration-200 hover:bg-swarm-gray-50;
  @apply divide-x divide-swarm-gray-300;

  &:hover {
    .onRowHoverVisible {
      visibility: initial;
    }
  }

  &Selected {
    @apply bg-swarm-gray-50;
  }
}

.tableCell {
  @apply flex h-12 w-full min-w-0 items-center overflow-hidden px-2 py-3;
  // for truncate
  & > div {
    @apply flex h-full w-full items-center;
  }
}

.table {
  @apply min-w-full border-collapse text-base leading-tight text-swarm-gray-700 relative;
  &.noVerticalSeparators {
    .tableRow {
      .tableCell {
        border: none;
      }
    }
  }
}
