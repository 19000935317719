.listHeader {
  @apply flex flex-row gap-4 items-center justify-between pb-4;
}
.listHeaderTitle {
  @apply flex items-center gap-2 font-semibold;
}

.listHeaderTitleIcon {
  @apply text-2xl;
}

.listItemsWrapper {
  @apply list-none leading-tight text-swarm-gray-700;
}

.listItem {
  @apply border-b border-swarm-gray-300 py-3.5 last:border-b-0 text-swarm-black;
  word-wrap: anywhere;

  &WithBottomBorder {
    @apply last:border-b;
  }
}

.inlineListItem {
  @apply flex flex-row;
}

.stackedListItem {
  @apply flex flex-col;
}

.dataListItemTitle {
  @apply flex shrink-0 pr-2 text-sm font-semibold text-swarm-gray-700;
}

.stackedDataListItemTitle {
  @apply w-full pb-2;
}

.inlineDataListItemTitle {
  @apply w-[40%] gap-2;
}

.emptyItem {
  @apply text-swarm-gray-400;
}
