.pageWrapper {
  @apply pr-3;
}

.icon {
  @apply inline-block;
}

.pipelineTitleCell {
  @apply flex justify-between w-full;
}

.pipelineTitle {
  @apply text-xl font-semibold
  text-swarm-black hover:cursor-pointer;
}

.connectionsNumber {
  @apply text-2xl font-bold text-swarm-black;
}

.connectionsByStatusNumber {
  @apply text-xl text-swarm-gray-700 text-lg;
}
