.createTeamModal {
  max-width: 640px;
}

.header {
  @apply text-xl font-bold -mt-2;
}

.divider {
  @apply -mx-8 mb-4 mt-6;
}
