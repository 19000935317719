.headerButtons {
  @apply flex  gap-2;
}

.title {
  @apply ml-2
  flex
  items-center
  text-2xl
  font-bold;
  & > svg {
    @apply mx-4;
  }
}

.detailsWrapper {
  @apply flex max-w-3xl flex-col p-2;
  & > * {
    &:not(:last-child) {
      @apply border-b;
    }
    @apply py-4;
  }
}
