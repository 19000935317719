.article {
  @apply mb-4;
  max-width: 276px;
}

.image {
  @apply w-full mb-4 cursor-pointer rounded transition-shadow;

  &:hover {
    @apply shadow-2xl;
  }
}

.title {
  @apply font-semibold text-lg mb-2;
}

.description {
  @apply text-sm text-swarm-gray-700;
}
