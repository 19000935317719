.profileWidthContainer {
  @apply grid min-w-[960px] max-w-7xl grid-cols-3;
}

.profileColumn {
  @apply px-2;
}

.profileHeader {
  @apply py-6;
}

.profileContentBlock {
  @apply pt-6;
}

.companyName {
  @apply text-swarm-black text-lg font-bold;
}

.experienceDuration {
  @apply text-swarm-black text-base;
}

.experienceItems {
  @apply border-l border-swarm-gray-400 ml-2 pl-6 mt-2 pt-1;
}

.multiExperienceItem {
  @apply mb-2;
}
.timeframe,
.location {
  @apply text-sm;
}
.title {
  @apply text-swarm-black text-base font-bold relative mb-1 block;
  &:before {
    @apply absolute bg-swarm-gray-400 rounded-full border-white border-4;
    left: -32px;
    top: 2px;
    width: 15px;
    height: 15px;
    content: '';
  }
}
.singleExperiencePosition {
  @apply text-lg font-semibold text-swarm-black mb-0.5;
}
.singleExperienceCompany {
  @apply text-base text-swarm-gray-900 mb-1;
}

.notesBadge {
  @apply py-1 bg-swarm-gray-100 rounded-full text-xs text-swarm-gray-900 border border-swarm-gray-400 text-xs flex items-center justify-center;
  min-width: 18px;
  height: 18px;
}

.reportButton {
  @apply text-xs text-swarm-gray-700 underline flex gap-1.5 items-center transition-colors;

  &:hover {
    @apply text-swarm-gray-900;
  }
}

.crmDataColumn {
  @apply col-span-2;
}
