.wrapper {
  @apply flex rounded border border-solid border-swarm-gray-400 pl-2 py-1 my-2 content-center w-full;

  &.invalid {
    @apply border-swarm-red hover:border-swarm-red;
  }
}

.placeholder {
  @apply text-swarm-gray-500 p-1 pl-2 w-0 whitespace-nowrap;
}

.growingTrigger {
  @apply grow;
  .triggerableArea {
    @apply flex h-full p-2 pr-3 grow;
    & > svg {
      @apply ml-auto self-end;
    }
  }
}

.tagsArea {
  @apply flex flex-wrap gap-1;
}

.tag {
  @apply flex grow-0 rounded flex items-center bg-swarm-gray-100 px-2 h-8;
  &Transparent {
    @apply bg-transparent px-0;
  }
}

.closingX {
  @apply cursor-pointer ml-1 opacity-60 hover:opacity-100;
}
