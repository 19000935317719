.connectedMembersContainer {
  @apply inline-flex;
}

.stackedConnectedMembers {
  @apply flex flex-row items-center gap-1;
}

.connectedMember {
  @apply mr-1 text-swarm-black whitespace-nowrap;
}

.connectedMembersList {
  @apply flex flex-col gap-2 py-2;
  .connectedMember {
    @apply text-swarm-white gap-1 flex flex-row items-center;
  }
}

.moreMembers {
  @apply text-swarm-gray-700 text-xs;
}
