.contentWrapper {
  width: 720px;
  &Full {
    @apply w-full;
  }
}

.header {
  @apply pb-6;
}

.subtitle {
  a {
    @apply underline;
  }
}

.nextButtons {
  @apply flex gap-2.5 w-full pt-6 items-center;
  &WithCancel {
    :first-child {
      @apply mr-auto;
    }
    :nth-child(2) {
      @apply order-2;
    }
  }
}

.reverseButtonsOrder {
  @apply flex-row-reverse;
}
