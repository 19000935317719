.googleButton {
  width: 191px;
  height: 46px;
  cursor: pointer;
  outline: none;
  background-image: url(./assets/btn_google_signin_dark_normal_web.png);

  &:disabled {
    background-image: url(./assets/btn_google_signin_dark_disabled_web.png);
  }

  &:focus {
    background-image: url(./assets/btn_google_signin_dark_focus_web.png);
  }

  &:active {
    background-image: url(./assets/btn_google_signin_dark_pressed_web.png);
  }
}
