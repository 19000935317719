.expandButton {
  @apply rounded border border-swarm-gray-300 cursor-pointer flex justify-center items-center text-xs py-1 mb-2 gap-2 text-swarm-black;

  &:hover {
    @apply bg-swarm-gray-50 border-swarm-gray-400;
  }

  &Inline {
    @apply inline-flex px-2 py-0.5 leading-normal;
  }
}
