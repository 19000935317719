.noResults {
  @apply py-24 flex flex-col items-center gap-4;
  &.errorPage {
    @apply h-full justify-center;
  }
}

.ilustrationHolder {
  @apply h-64 flex items-center;
}

.ilustration {
  @apply max-w-lg max-h-full;
}

.heading {
  @apply text-3xl font-bold leading-tight;
}

.subheading {
  @apply leading-6 text-center whitespace-pre;
}
