.nameCellWrapper {
  & > :first-child {
    @apply mr-2;
  }

  @apply flex w-full items-center;
}

.nameWrapper {
  @apply inline-block overflow-hidden text-ellipsis;

  & > div {
    @apply w-full;
  }
}
