.divider {
  text-align: center;
  padding: 16px 0;
  position: relative;
  overflow: hidden;
}

.divider:before,
.divider:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 50%;
  height: 1px;
  background: #cdcdcd;
}

.divider:before {
  left: 0;
  margin-left: -15px;
}

.divider:after {
  right: 0;
  margin-right: -15px;
}

.divider-text {
  color: #000;
}
