.formContent {
  @apply my-4;
}

.rolesTrigger {
  @apply capitalize
    h-10
    flex
    justify-between
    w-full
    cursor-pointer
    rounded border border-swarm-gray-400
    bg-none
    py-2.5
    px-3
    leading-5;
}

.emailInput {
  @apply mb-2;
  input {
    @apply h-10;
  }
}

.emailInputMessage {
  @apply mt-0.5 ml-0.5 -mb-1;
}

.formMessage {
  @apply mt-0.5 ml-0.5 mb-2;
}

.divider {
  @apply -mx-8 mb-0 mt-4;
}
