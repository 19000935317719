.placeholder {
  @apply text-swarm-gray-400;
}

.paragraph {
  white-space: break-spaces;

  a {
    @apply underline;
  }
}

.link {
  @apply break-words underline;
}
