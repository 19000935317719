.heading {
  @apply text-swarm-black mb-6 text-3xl font-bold flex gap-8 items-center;
}

.previewItem {
  @apply text-swarm-black pt-2 flex gap-1 flex-wrap;
}

.connectorPreview {
  @apply bg-swarm-gray-200 inline-block py-1 px-2 rounded;
}

.separator {
  @apply border-t border-t-swarm-gray-400 mt-2 mb-2;
}

.previewMessage {
  @apply text-swarm-black whitespace-pre-wrap;

  a {
    @apply underline;
  }
}

.receiversInfo {
  @apply pt-2;
}
