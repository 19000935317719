.wrapper {
  @apply mx-auto mt-8 mb-16;
  max-width: 640px;
}

.content {
  @apply mt-8;
}

.heading {
  @apply text-center text-xl font-bold;
}

.subheading {
  @apply text-center text-swarm-gray-700 text-base;
}

.planBenefit {
  @apply flex my-4 h-52 rounded rounded-lg border border-swarm-gray-300 overflow-hidden;
  &:nth-child(even) {
    @apply flex-row-reverse;
  }
}

.learnMoreLink {
  @apply text-swarm-black border-b border-swarm-gray-500 hover:border-swarm-gray-800 cursor-pointer;
}

.benefitTitle {
  @apply relative w-1/2 flex justify-end;
}

.benefitHeading {
  @apply text-3xl font-bold absolute left-1 right-4 w-1/2 p-4 h-full flex items-center justify-center;
}

.benefitDescription {
  @apply w-1/2 bg-swarm-gray-100 p-4 text-swarm-gray-700;
  a {
    @apply text-swarm-black underline whitespace-nowrap;
  }
}

.benefitSubheading {
  @apply text-xl font-semibold pb-2 text-swarm-black;
}

.goToSwarmButton {
  @apply mx-auto mt-8 w-fit;
}

.prioritySupport {
  @apply border border-swarm-gray-400 rounded-sm flex-1 mt-4 p-4 flex items-center text-swarm-gray-700 justify-start gap-4;
}

.supportHeading {
  @apply text-xl font-medium font-semibold mb-1 text-swarm-black;
}
