.form {
  max-width: 480px;
}

.noteTextareaWrapper {
  @apply relative my-2;
  &.editMode {
    .noteTextareaHeader {
      @apply hidden;
    }
    .noteTextarea {
      @apply pt-2 h-24;
    }
  }
}

.noteTextareaHeader {
  @apply flex items-center absolute top-3 left-3 pointer-events-none;
}

.noteTextarea {
  @apply pt-10 w-full pt-16;
}

.formFooter {
  @apply flex justify-between items-center w-full;
  &EditMode {
    @apply justify-end gap-2;
  }
}

.markNotePrivate {
  @apply text-swarm-gray-800 flex items-center gap-2;
}
