.container {
  @apply py-4;
}

.content {
  @apply pt-4 flex justify-evenly gap-4 font-light;
}

.planEnterprise {
  @apply border border-swarm-gray-400 rounded flex-1 mt-4 p-4 flex justify-between items-center;
}

.planName {
  @apply text-2xl font-medium font-semibold mb-2;
}
