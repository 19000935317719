.connectedMembers {
  @apply flex flex-col gap-2 items-start;
}

.connectedMember {
  @apply inline-flex gap-1 items-center border border-swarm-gray-400 rounded-sm p-1 px-2;
}

.description {
  @apply text-sm;
}

.expander {
  @apply flex gap-1 pt-2;
}

.expanderLabel {
  @apply text-sm text-swarm-gray-800;
}
