.subscriptionItem {
  @apply border-b border-swarm-gray-400 py-3 pb-3.5 text-swarm-gray-700;
  &:first-of-type {
    @apply pt-0;
  }
  &:last-of-type {
    @apply border-0;
  }
  &Header {
    @apply font-semibold text-swarm-black mb-0.5;
  }
}

.addPayment {
  @apply underline;
}

.buttons {
  @apply flex gap-2 mt-4;
}

.allPlansButton {
  @apply ml-auto;
}
