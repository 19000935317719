.tableWrapper {
  @apply pt-6;
}

.relationshipCellWrapper {
  @apply flex items-start;
  img {
    @apply h-5 mr-2;
  }
}

.relationship {
  @apply cursor-help;
}
