.menuTrigger {
  @apply overflow-hidden
  flex
  justify-between
  items-center
  font-medium
  text-base
  rounded
  border-swarm-gray-400
  border
  cursor-pointer;
  padding: 5px;
  &Open {
    .triggerChevron {
      @apply transform rotate-180;
    }
  }
  &:hover,
  &Open {
    @apply bg-swarm-gray-50;
  }
}

.triggerChevron {
  @apply mr-1 shrink-0;
}

.labelWrapper {
  @apply shrink overflow-hidden;
}

.label {
  @apply rounded flex
  flex-row
  font-semibold
  items-center gap-2;
}

.iconOnly {
  @apply w-10 overflow-hidden;
}

.alert {
  @apply w-3 h-3 mr-auto;
}
