.profileWidthContainer {
  @apply flex min-w-[960px] max-w-7xl;
}

.profileColumn {
  @apply px-4 w-1/3;
  &:first-child {
    @apply pl-2;
  }
  &WithLogo {
    min-width: 450px;
  }
}

.profileHeader {
  @apply py-6;
}
