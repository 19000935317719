.wrapper {
  @apply relative z-100;
}

.curtain {
  @apply fixed inset-0;
  background-color: rgba(27, 25, 24, 0.5);
}

.scrollArea {
  @apply fixed inset-0 overflow-y-auto;
}

.container {
  @apply flex min-h-full items-center justify-center text-center;
}

.panel {
  @apply w-full transform rounded bg-white p-8 text-left align-middle shadow-xl transition-all;
}

.closeButton {
  @apply absolute top-1 right-1;
}

/* magic border stuff */

@keyframes rotateGradient {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.magicBorderWrapper {
  @apply p-2 rounded-lg relative overflow-hidden;
}

.magicBorder {
  @apply relative w-full h-full;

  &:before {
    @apply absolute rounded-lg;
    content: '';
    left: 50%;
    top: 50%;
    // size and margins below give best effect for our typical modal size (near-square or
    // at least less than 2:1 ratio). for much longer modals the dimentions would have to
    // be increased accordingly, so there would be no gaps in border (it's a rotating square)
    width: 1000px;
    height: 1000px;
    margin-left: -500px;
    margin-top: -500px;
    background: radial-gradient(129.58% 92.71% at 0% 0%, #ffad8a 22.81%, rgba(255, 175, 140, 0) 100%),
      radial-gradient(30.03% 30.07% at 73.97% 74.86%, #fdd167 0%, rgba(253, 209, 103, 0) 100%),
      radial-gradient(71.62% 68.97% at 74.24% 25.41%, #4a6aef 0%, rgba(54, 86, 218, 0) 80.57%),
      radial-gradient(300% 150% at 0% 99.95%, #ff9c57 25.95%, rgba(255, 162, 92, 0) 100%), #fff;
    animation: rotateGradient 20s linear infinite;
    transform-origin: center;
  }
}
