.tagsList {
  @apply inline-flex gap-1;
}

.assignedTagsTooltip {
  @apply p-1 inline-flex gap-2 items-center;
}

.preview {
  @apply max-w-full overflow-hidden relative;
}

.tagsCellButtonWrapper {
  @apply max-w-full w-full;
}

.tagsCellButton {
  @apply w-full;
}

.button {
  @apply p-1 transition-all rounded w-full cursor-pointer relative max-w-full overflow-hidden;
  min-width: 60px;
  &:hover {
    @apply bg-swarm-gray-200 pr-6 delay-100;
    .icon {
      @apply opacity-100 transition-all;
    }
  }
}

.active {
  @apply bg-swarm-gray-200 pr-6;
  .icon {
    @apply opacity-100 rotate-180;
  }
}

.empty {
  @apply px-1 h-full;
}

.icon {
  @apply absolute right-0.5 top-2 w-6 opacity-0 transition-all duration-300;
}
