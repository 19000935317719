.testimonial {
  @apply mb-6;
}

.quote {
  @apply text-lg leading-normal mb-6;

  &::before,
  &::after {
    content: '"';
  }
}

.author {
  @apply block font-bold not-italic;
}

.role {
  @apply block not-italic text-swarm-gray-700;
}
