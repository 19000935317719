.container,
.label {
  @apply flex flex-row items-center gap-1;
}

.container {
  @apply justify-between w-full;
}

.label {
  @apply shrink truncate;
}

.connectedMember {
  @apply mr-1 text-swarm-black whitespace-nowrap;
}

.requestIntro {
  @apply flex shrink-0;
}
