.opportunityTile {
  @apply p-4 mb-4;
}

.row {
  @apply mb-2 flex flex-row items-center gap-2;
}

.rowBetween {
  @apply justify-between;
}

.label {
  @apply text-swarm-gray-700 font-light;
}

.small {
  @apply text-xs;
}

.name {
  @apply text-lg font-semibold;
}

.description {
  @apply text-sm text-swarm-gray-700;
}
