.input {
  @apply w-full transition-[padding] focus:text-swarm-gray-900;
}

.baseInput,
.lgInput {
  @apply px-3;
}

.lgInput {
  @apply !text-lg;
}

.borderedInput {
  @apply hover:focus:border-swarm-blue;
}

.clearableInput {
  @apply border-none
        bg-swarm-gray-100 hover:bg-swarm-gray-200
        focus:bg-white
        focus:ring-[1.5px]
        focus:ring-swarm-gray-400
        focus:hover:bg-white
        disabled:bg-white;
}

.borderedInput:not(:placeholder-shown),
.clearableInput:not(:placeholder-shown) {
  @apply focus:ring-0
        focus:ring-transparent
        focus:ring-offset-0;
}

.borderedInput:not(:placeholder-shown) {
  @apply focus:border-swarm-gray-400;
}

.clearableInput:not(:placeholder-shown) {
  @apply border-none
        focus:bg-swarm-gray-100;
}

.borderedInputInvalid {
  @apply !border-swarm-red hover:border-swarm-red;
}

.clearableInputInvalid {
  @apply bg-swarm-red-light hover:bg-swarm-red-light;
}

.borderedInputInvalid,
.clearableInputInvalid {
  @apply text-swarm-red
        placeholder-swarm-red
        hover:placeholder-swarm-red;
}

.borderedInputValid {
  @apply border-swarm-green hover:border-swarm-green;
}

.clearableInputValid {
  @apply bg-swarm-green-light hover:bg-swarm-green-light;
}

.borderedInputValid,
.clearableInputValid {
  @apply text-swarm-green-dark
        placeholder-swarm-green-dark
        hover:placeholder-swarm-green-dark;
}

.inputWithIcon {
  @apply !pl-10;
}

.inputWrapper {
  @apply relative my-2 w-full;
}

.inputWrapper:hover > .inputIcon > svg {
  @apply stroke-swarm-gray-700;
}

.inputIcon {
  @apply pointer-events-none
        absolute inset-y-0 left-0
        flex items-center
        pl-3;
}

.inputIcon > svg {
  @apply stroke-swarm-gray-500;
}

.inputWrapper:hover > .inputIconValid > svg,
.inputIconValid > svg {
  @apply stroke-swarm-green-dark;
}

.inputWrapper:hover > .inputIconInvalid > svg,
.inputIconInvalid > svg {
  @apply stroke-swarm-red;
}

.inputWrapper:hover > .inputIconDisabled > svg {
  @apply stroke-swarm-gray-500;
}

.inputWrapper:focus-within > .borderedInputIcon > svg {
  @apply stroke-swarm-blue;
}

.inputWrapper:focus-within > .clearableInputIcon > svg {
  @apply stroke-swarm-black;
}

.inputWrapper:focus-within > .filledInputIcon > svg {
  @apply stroke-swarm-gray-500;
}

.inputAddon,
.inputClearButton {
  @apply absolute inset-y-0 right-0
        flex items-center
        !pr-3;
}

.borderedInputValid,
.clearableInputValid,
.borderedInputInvalid,
.clearableInputInvalid,
.borderedInputHasClearBtn,
.clearableInputHasClearBtn {
  @apply !pr-10;
}

.input:placeholder-shown ~ button.inputClearButton,
.input:not(:focus) ~ button.inputClearButton,
.input:focus + span,
.input:disabled + span {
  @apply hidden;
}

.input:disabled:hover {
  @apply placeholder-swarm-gray-400
        hover:placeholder-swarm-gray-400;
}

.borderedInput:disabled {
  @apply bg-swarm-gray-50 text-swarm-gray-700 border-swarm-gray-200 hover:border-swarm-gray-200;
}

.clearableInput:disabled {
  @apply bg-white
        hover:bg-white;
}
