.variablesInstruction {
  @apply pt-2 pb-4;
}

.receiversInfo {
  @apply pt-1;
}

.forwardableCheckbox {
  @apply py-6;
}
