.card {
  @apply flex flex-col
    w-full
    rounded border
    mb-2.5 py-6 px-8
    text-base font-normal;

  &Default {
    @apply bg-white border-swarm-gray-400;
  }
  &Orange {
    @apply bg-swarm-orange-light border-swarm-orange-darken;
  }
  &Yellow {
    @apply bg-swarm-yellow-light border-swarm-yellow-darken;
  }
  &Blue {
    @apply bg-swarm-blue-light border-swarm-blue-darken;
  }
  &Pink {
    @apply bg-swarm-pink-light border-swarm-pink-darken;
  }
  &Red {
    @apply bg-swarm-red-light border-swarm-red-darken;
  }
  &Green {
    @apply bg-swarm-green-light border-swarm-green-darken;
  }
  &Gray {
    @apply bg-swarm-gray-100 border-swarm-gray-500;
  }
}
