.pageWrapper {
  @apply mx-auto
    flex flex-col
    w-fit
    py-3;
}

.contentWrapper {
  @apply min-w-[640px];
}
