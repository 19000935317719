.stickyHeader {
  @apply sticky top-0 z-10 bg-white shadow-swarm-border-bottom;
}

.tableHeader {
  @apply relative flex h-12 w-full transition-all;
  &WithBulkActions {
    @apply h-24 border-t border-swarm-gray-300;
  }
  &MassActionsVisible {
    @apply h-36;
  }
}

.tableHeadersWrapper {
  @apply divide-x divide-swarm-gray-300;
}

.tableTh {
  @apply absolute h-12 text-ellipsis whitespace-nowrap text-left text-sm font-medium;
  &:first-of-type {
    border-left: none !important;
  }
}

.tableHeaderWithBulkActions {
  .tableTh {
    @apply top-12;
  }
}

.tableThContent {
  @apply flex h-full items-center py-3 px-2;
}

.tableThSortable {
  @apply cursor-pointer select-none gap-1 hover:bg-swarm-gray-100;
}

.resizer {
  @apply absolute top-0 z-10 h-full cursor-col-resize touch-none select-none bg-transparent opacity-0 transition-opacity;
  width: 20px;
  right: -11px;

  &:before {
    @apply absolute top-0 left-1/2 -ml-px block h-full w-0.5 bg-swarm-gray-400;
    content: '';
  }

  &:hover {
    @apply opacity-100;
  }
}

.bulkActions {
  @apply flex flex-row items-center gap-2 h-8 w-full z-100 text-swarm-black text-sm mt-2 pl-2;
}

.massActionsSwitchFrame {
  @apply h-12 w-full bg-swarm-yellow-soft absolute top-24 flex items-center pl-4 text-swarm-black text-sm;
}

.massActionsSwitch {
  @apply ml-1 cursor-pointer underline;
}
