.editForwardableTrigger {
  @apply relative w-full h-24 cursor-pointer;
}

.forwardableTextarea {
  @apply absolute inset-0 opacity-80;
}

.editForwardableTriggerOverlay {
  @apply absolute inset-0 bg-swarm-gray-300 opacity-0 transition-opacity duration-300 flex items-center justify-center;
}

.editForwardableTrigger:hover .editForwardableTriggerOverlay {
  @apply opacity-70;
}

.forwardableModal {
  width: 640px;
}

.heading {
  @apply text-swarm-black mb-8 text-3xl font-bold flex gap-8 items-center;
}

.buttons {
  @apply flex justify-between gap-4 mt-8;
}
