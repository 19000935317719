.mobileBlocker {
  @apply bg-swarm-gray-300 fixed inset-0 overflow-auto;
  &Content {
    @apply pt-12 flex flex-col items-center text-center;
    & > div {
      @apply text-center pt-16 px-2;
      img {
        @apply mx-auto;
      }
      h2 {
        @apply text-2xl py-4;
      }
      p {
        @apply text-swarm-gray-700 text-base;
      }
    }
  }
}

.offButton {
  @apply mx-auto mt-6;
}
