.wrapper {
  @apply w-full
        flex flex-row items-center justify-center
      p-4
      max-w-5xl
      mx-auto;
  min-width: 480px;
  &Flexible {
    @apply max-w-fit;
  }
}

.main {
  @apply w-full max-w-lg
    text-center shrink-0;
  &Flexible {
    @apply max-w-fit;
  }
}

.card {
  @apply w-full p-8
    text-center
    place-self-center
    rounded-lg
    border border-swarm-gray-400 shadow-lg
    bg-white
    shrink-0;
}

.aside {
  @apply pr-16;
  @media screen and (max-width: 920px) {
    display: none;
  }
}

.header {
  @apply mb-5;

  &Image {
    @apply mb-2 mx-auto;
  }

  &Title {
    @apply py-2 text-3xl font-bold;
  }

  &Subtitle {
    @apply py-1 text-xl font-semibold;
  }

  &Description {
    @apply mb-5 text-base text-swarm-gray-700;
  }
}

.footer {
  @apply mt-4;
}

.signout {
  @apply underline text-swarm-gray-600 text-sm mt-6;
}
