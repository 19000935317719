@import 'fonts/font-faces';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'common/global-zindex';

body {
  @apply antialiased;
  letter-spacing: -0.0125em;
  font-feature-settings: 'ss01', 'ss03', 'ss05', 'liga' 0, 'dlig' 0;
}

button {
  letter-spacing: -0.0125em;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='week'],
[multiple],
textarea,
select {
  @apply rounded
  border-swarm-gray-400
  py-1.5 placeholder-swarm-gray-500 hover:border-swarm-gray-700 focus:border-swarm-gray-700
  hover:placeholder-swarm-gray-700 focus:placeholder-swarm-gray-300 focus:hover:placeholder-swarm-gray-300 disabled:cursor-not-allowed disabled:border-swarm-gray-200
  disabled:placeholder-swarm-gray-400;
  font-feature-settings: 'ss01', 'ss03', 'ss05', 'liga' 0, 'dlig' 0;
}
