.wrapper {
  @apply w-full;
  @apply text-base;
  &Links {
    @apply mb-1;
  }
}

.tabsList {
  @apply flex items-start justify-items-start content-start gap-6;
  @apply border-b border-b-swarm-gray-400;
  &InPage {
    @apply mx-2;
  }
}

.tab {
  @apply flex gap-2;
  padding: 0.5em 0;
  margin-bottom: -1px;
  outline: none;
}

.unselectedTab {
  @apply text-swarm-gray-700;

  &:hover {
    @apply text-swarm-gray-900;
  }
}

.selectedTab {
  @apply text-swarm-black;
  @apply border-b-swarm-black border-b-2;
}

.disabledTab {
  @apply text-swarm-gray-400;

  &:hover {
    @apply text-swarm-gray-400 cursor-not-allowed;
  }
}

.contentWrapper {
  @apply py-6;
  &InPage {
    @apply py-0;
  }
}

.contentPanel {
  outline: none;
}
