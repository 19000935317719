.modal {
  @apply w-96;
}

.wrapper {
  @apply text-center flex flex-col items-center justify-center gap-6 pt-4;
}

.heading {
  @apply text-swarm-black text-3xl font-bold;
  &First {
    @apply -order-1;
  }
}

.icon {
  @apply inline-flex h-16 w-16 items-center justify-center rounded-full border border-swarm-gray-400 shadow-lg relative;
  svg,
  img {
    @apply size-8;
  }
}

.iconBadge {
  @apply absolute -top-2 -right-2
  flex items-center justify-center
  bg-swarm-yellow-light
  border border-swarm-yellow-darken
  rounded-full;
  height: 30px;
  width: 30px;
  svg,
  img {
    @apply size-4;
  }
}

.button {
  @apply mt-6;
}
