.bulkAddToPipelineButton {
  @apply cursor-pointer transition-all;
  &Open {
    @apply text-black;
  }
}

.emptyInfoWrapper {
  @apply flex flex-col items-center justify-center gap-2 py-4;
}
