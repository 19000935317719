.tile {
  @apply px-2.5 py-3 text-swarm-black;
}

.inner {
  @apply flex justify-between;
}

.content {
  @apply flex items-center gap-2 text-lg font-bold;
}

.icon {
  @apply w-8 ml-1;
}

.description {
  @apply flex flex-col;
}

.title {
  @apply text-lg;
}

.subtitle {
  @apply text-base font-normal text-swarm-gray-700;
}
