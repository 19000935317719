.loadingStateWrapper {
  @apply flex justify-center p-4;
}

.item {
  @apply flex items-center gap-2;
}

.actionsButtonsWrapper {
  @apply w-full flex justify-end gap-2;
}
