.score {
  @apply flex flex-row gap-2 items-center px-2 py-1;
}

.indicator {
  @apply flex gap-0.5 items-end w-4 h-4;
  & > div {
    @apply w-1 bg-swarm-gray-500;
    &:nth-child(1) {
      height: 6px;
    }
    &:nth-child(2) {
      height: 11px;
    }
    &:nth-child(3) {
      height: 16px;
    }
  }
  &Level {
    &Weak {
      & > div {
        &:nth-child(1) {
          @apply bg-swarm-blue;
        }
      }
    }
    &Familiar {
      & > div {
        &:nth-child(1),
        &:nth-child(2) {
          @apply bg-swarm-yellow;
        }
      }
    }
    &Strong {
      & > div {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          @apply bg-swarm-green;
        }
      }
    }
  }
}

.noScore {
  @apply relative;
  &:after {
    @apply content-[''] block w-0.5 h-5 bg-white absolute left-1.5 top-0 box-content border-r-2 border-r-swarm-red -rotate-45;
  }
}

.scoreDetails {
  @apply flex flex-row justify-between gap-4;
}

.scoreDetailsWrapper {
  @apply p-1 flex flex-col gap-2;
}

.moreConnections {
  @apply text-swarm-gray-700 text-xs pb-1;
}
