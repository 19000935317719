.notification {
  @apply relative my-2 w-full rounded border p-2 text-sm text-swarm-black;
  a {
    @apply font-bold underline;
  }
  h3 {
    @apply font-bold;
  }
  &.black {
    color: white;
    svg {
      color: white;
    }
  }
  &.closable {
    @apply pr-10;
  }
}

.notificationHeading {
  @apply flex items-center gap-2 font-bold;
}

.notificationContent {
  @apply flex items-center gap-2 whitespace-pre-wrap;
}

.close {
  @apply absolute right-2 top-2 cursor-pointer;
}
