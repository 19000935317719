.task {
  @apply w-full
    flex justify-between
    relative
    border border-swarm-gray-400 rounded
    gap-2 py-3 px-4 mb-3
    transition-shadow;

  &:hover {
    @apply shadow-md;
  }

  &Complete {
    @apply bg-swarm-green-light border-swarm-green;

    .subTitle {
      @apply text-swarm-green-dark;
    }

    &:hover {
      @apply cursor-default;
    }
  }
}

.hasAction {
  @apply cursor-pointer;
}

.leftSide,
.rightSide {
  @apply flex items-center gap-2;
}

.heading {
  @apply flex flex-col;
}
.title {
  @apply font-semibold leading-tight;
}
.subtitle {
  @apply text-xs text-swarm-gray-700 font-light;
}

.count {
  @apply border px-2 leading-8 rounded text-base;
}

.actions {
  @apply flex gap-4;
}

.actionButton {
  @apply text-swarm-gray-700 hover:text-swarm-gray-900 font-normal;
}
