.pendingInvitationsAlert {
  @apply w-3 h-3;
}

.teamDropdownTrigger {
  @apply flex justify-between items-center font-medium text-base rounded relative border-swarm-gray-400 border cursor-pointer;
  padding: 5px;
  &Open {
    .triggerChevron {
      @apply transform rotate-180;
    }
  }
  &:hover,
  &Open {
    @apply bg-swarm-gray-50;
  }
}

.teamLabel {
  @apply rounded flex
  flex-row
  items-center max-w-full gap-2;
}

.iconOnly {
  @apply w-10 overflow-hidden;
}

.divider {
  @apply my-1 -mx-1;
}

.otherAction {
  @apply flex items-center text-base cursor-pointer p-2 whitespace-nowrap gap-2;
  &:hover {
    @apply bg-swarm-gray-100;
  }
}

.subtitle {
  @apply text-xs
  font-light
  text-swarm-gray-500;
}

.switchItem {
  @apply w-full relative overflow-hidden;

  &Role {
    @apply absolute opacity-0 right-0 top-0 translate-x-10 transition duration-300;
  }
}

.switchItem:hover {
  .switchItemRole {
    @apply opacity-100 translate-x-0;
  }
}

.roleAndPlanIndicator {
  @apply flex flex-row px-2 pt-2 pb-1;

  & > div {
    @apply w-1/2;
  }

  &Title {
    @apply font-light text-swarm-gray-700 uppercase block;
    font-size: 10px;
  }
}
