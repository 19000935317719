.wrapper {
  @apply flex flex-col gap-1;
}

.filterActions {
  @apply flex gap-1 items-center justify-end w-full min-w-60; // min-w-60 is used to prevent dropdown from changing width when selectAll label changes
}

.listFilterTrigger {
  @apply cursor-pointer;
}

.selectAll {
  @apply mr-auto;
}
