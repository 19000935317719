.sortableItem {
  @apply rounded bg-white;
}

.activeItem {
  @apply shadow-swarm-lg-soft border border-swarm-gray-400 cursor-grabbing rounded h-10;
  &.uncheckedItem {
    button:hover {
      @apply bg-white; // to prevent the hover effect when cursor moves from handler while dragging
    }
  }
}

.grabElement {
  @apply absolute size-9 right-0 top-0 z-50 flex justify-center items-center cursor-grab;
  &:active {
    @apply cursor-grabbing;
  }
}

.sortableInner {
  @apply w-full relative rounded;
}
