.logosHeading {
  @apply uppercase text-swarm-gray-700 text-xs font-bold leading-tight my-8 text-center;
}

.logosWrapper {
  @apply flex flex-wrap justify-center;
}

.logoWrapper {
  @apply w-1/3 pb-6;
}
