.avatarCollection {
  @apply mx-1.5;
  @apply flex flex-row-reverse items-center justify-center;
  .avatarCollectionElement {
    @apply -mx-1 flex items-center justify-center overflow-hidden rounded-full border border-white relative;
    &Etc {
      @apply -mx-1.5;
      &:nth-child(3) {
        @apply -ml-3;
      }
    }
  }
  &xs {
    @apply mx-1;
    .avatarCollectionElement {
      @apply border;
      margin-left: -0.2rem;
      margin-right: -0.2rem;
      &Etc {
        @apply -mx-1.5;
      }
    }
  }
  &sm {
    @apply mx-1.5;
    .avatarCollectionElement {
      @apply -mx-1 border-2;
      &Etc {
        @apply -mx-2;
      }
    }
  }
  &md {
    @apply mx-2;
    .avatarCollectionElement {
      @apply -mx-2 border-2;
      &Etc {
        @apply -mx-4;
      }
    }
  }
  &xl {
    @apply mx-4;
    .avatarCollectionElement {
      @apply -mx-4 border-4;
      &Etc {
        @apply -mx-8;
      }
    }
  }
  &OnBlack {
    .avatarCollectionElement {
      @apply border-swarm-black;
    }
  }
  &OnGray {
    .avatarCollectionElement {
      @apply border-swarm-gray-100;
    }
  }
}
