.dataItem {
  @apply border-b border-swarm-gray-300 py-2 flex items-start;
  &Label {
    @apply flex items-center gap-2 text-swarm-gray-700;
    width: 40%;
  }
  &Value {
    width: 60%;
    a {
      @apply underline;
    }
  }
}
