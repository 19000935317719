.wrapper {
  @apply grid grid-cols-[2fr_minmax(auto,max-content)_2fr]
    items-center
    justify-center
    gap-2
    border-t
    bg-white px-2 py-[10px];
}

.buttonsWrapper {
  @apply flex
  items-center;
}

.chevron {
  @apply font-bold;
}

.chevronButtonLeft {
  @apply mx-1 pl-1.5 pr-3;
}

.chevronButtonRight {
  @apply mx-1 pl-3 pr-1.5;
}

.pageSizeSelectWrapper {
  @apply flex justify-end;
  padding-right: 75px; /* caused by the intercom button */
}

.pageSizeSelect {
  @apply w-fit justify-end;
}

.paginationButton {
  @apply mx-1;
}

.paginationButtonActive {
  @apply border border-swarm-gray-500 px-3.5;
}
