.googleButton {
  @apply flex flex-row items-center font-medium p-0.5 rounded-sm text-white;
  height: 46px;
  background: #4285f4;

  &:hover,
  &:focus,
  &:active {
    background: #3367d6;
  }
}

.logo {
  @apply shrink-0 rounded-sm bg-white h-full align-middle p-2.5;
  img {
    width: 22px;
    height: 22px;
  }
}

.label {
  @apply flex-grow text-center text-lg px-4;
}
