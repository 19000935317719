.sidebarToggle {
  @apply w-full h-8 overflow-hidden px-0.5;
  .swarmIcon {
    @apply opacity-100 transition-opacity duration-300;
    margin-left: 0.5px;
  }
  .expand-icon {
    @apply transition-opacity delay-300 cursor-pointer ml-0.5;
  }
  &:hover {
    .expandIcon {
      @apply opacity-100 delay-300;
    }
    .swarmIcon {
      @apply opacity-0 duration-300;
    }
  }
  &Inner {
    @apply flex items-center justify-between h-full w-52;
  }
}

.logoWrapper {
  @apply w-32 h-8 flex relative;
}

.sidebarLogo {
  @apply h-8 ml-0.5;
}

.collapseIcon {
  @apply h-6 w-6 text-swarm-gray-400 transition-all duration-300 cursor-pointer;
  &:hover {
    @apply -translate-x-1 text-swarm-gray-500;
  }
}

.innerIcon {
  @apply absolute left-0 top-0 w-8 h-8;
}

.expandIcon {
  @apply h-6 w-6 absolute left-2 top-1 text-swarm-white opacity-0 cursor-pointer z-10;
}

.swarmIcon {
  @apply absolute left-3 top-2 opacity-0;
  width: 13px;
  height: 15px;
}
