.appliedFilter {
  @apply inline-flex items-center gap-1 rounded-md bg-swarm-gray-200 py-1 px-2 border border-transparent transition-all h-10;
}

.openFilter {
  @apply border-swarm-gray-400;
}

.emptyValueAlert {
  @apply bg-swarm-red-light text-swarm-red-darken;
}

.title {
  @apply font-semibold ml-2;
}

.removeIconWrapper {
  $pad: 6px;

  padding: $pad;
  margin: -$pad;

  z-index: 1;
}

.removeIcon {
  @apply cursor-pointer text-swarm-black size-4 transition-all;
  &:hover {
    @apply scale-105;
  }
}

.emptyValueAlert .removeIcon {
  @apply text-swarm-red-darken;
}
