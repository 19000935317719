.vcard {
  @apply flex gap-4;
}

.title {
  @apply text-3xl font-semibold;
}

.specialization {
  @apply flex pt-1 text-lg;
  &Header {
    @apply text-swarm-gray-700;
  }
}

.where {
  @apply leading-6 text-swarm-gray-900;
}

.socials {
  @apply mt-2 flex flex-wrap;
  max-width: 500px;
}
