.headerButtons {
  @apply flex  gap-2;
}

.title {
  @apply ml-2
  flex
  items-center
  text-2xl
  font-bold;
}
