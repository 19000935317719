.sidebarInner {
  @apply h-full flex flex-col overflow-y-auto;
}

.sidebarHeader,
.sidebarFooter {
  @apply z-20 shrink-0 bg-swarm-gray-100 sticky px-4;
}

.sidebarHeader {
  @apply top-0 pt-4 bg-swarm-gray-100 h-16;
}

.sidebarFooter {
  @apply bottom-0 border-t border-t-swarm-gray-400 py-2.5;
}

.sidebarContent {
  @apply px-4 h-full flex flex-col gap-2 overflow-y-auto;
}

.sidebarWidgets {
  @apply flex flex-col gap-2;
}

.sidebarContentCollapsed {
  @apply pr-0;
}

.push {
  @apply flex-1;
}
