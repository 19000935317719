.colorSelect {
  @apply flex items-center justify-between;
  &Tag {
    @apply w-9 h-9 px-0 flex cursor-pointer;
  }
}

.createTagForm {
  @apply flex flex-col gap-4;
}

.inputLabel {
  @apply uppercase text-xs font-semibold text-swarm-gray-700 mb-2 block;
}

.info {
  @apply text-xs font-semibold text-swarm-gray-700 mb-2 block;
}

.divider {
  @apply -mx-8 mt-2 -mb-2;
}
