.emailPreview {
  &Title {
    @apply bg-swarm-gray-200 -mt-6 -mx-8 rounded-t px-2 py-3;
    h4 {
      @apply text-swarm-black text-sm leading-4;
      svg,
      span {
        @apply mr-1 inline;
      }
    }
    span {
      @apply text-xs uppercase font-bold;
    }
  }
  &Content {
    @apply rounded-t bg-white border-t border-swarm-gray-400 -mt-1 -mx-8 px-8 text-center;
    img {
      @apply mx-auto my-10 h-12;
    }
    h2 {
      @apply text-2xl text-swarm-black font-bold mb-8;
    }
    p {
      @apply text-lg text-swarm-black leading-normal mb-6;
    }
  }
}
