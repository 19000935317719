.pipelineFormModal {
  width: 640px;
}

.header {
  @apply text-xl font-bold text-swarm-black pb-6;
}

.label {
  @apply mt-4 mb-1 flex items-center gap-2;
  &:first-of-type {
    @apply mt-0;
  }
}

.textarea {
  @apply w-full mt-1;
}

.formFooter {
  @apply flex items-center pt-4;
}

.markPipelinePrivate {
  @apply mr-auto text-swarm-gray-800 cursor-pointer flex items-center gap-2;
}
