.integration {
  @apply py-2 flex gap-2 items-center justify-between text-base text-swarm-gray-700;

  &Icon {
    @apply flex size-10 items-center justify-center rounded border border-swarm-gray-200 mr-4;
  }

  &Description {
    @apply text-swarm-gray-700;

    &Header {
      @apply font-semibold text-swarm-black mb-0.5;
    }
  }

  &Action {
    @apply flex gap-2;
  }

  &LastSync {
    @apply pt-2;

    &Value {
      @apply text-swarm-black;
    }
  }
}
