.avatar {
  @apply flex shrink-0 items-center justify-center rounded-full font-light uppercase text-white;
  font-size: 10px;

  //sizes
  &.xs {
    @apply size-4 text-xxs;
  }
  &.sm {
    @apply size-5;
    font-size: 10px;
  }
  &.md {
    @apply size-7 text-xs;
  }
  &.lg {
    @apply size-12 text-2xl;
  }
  &.xl {
    @apply size-16 text-3xl;
  }
  &.xxl {
    @apply size-24 text-4xl;
  }
  //colors
  &.blue {
    @apply bg-swarm-blue border-swarm-blue;
    &.outline {
      @apply text-swarm-blue;
    }
  }
  &.yellow {
    @apply bg-swarm-yellow border-swarm-yellow text-swarm-black;
    &.outline {
      @apply text-swarm-yellow;
    }
  }
  &.orange {
    @apply bg-swarm-orange border-swarm-orange;
    &.outline {
      @apply text-swarm-orange;
    }
  }
  &.coral {
    @apply bg-swarm-coral border-swarm-coral text-swarm-black;
    &.outline {
      @apply text-swarm-coral;
    }
  }
  &.bubblegum {
    background-color: #f87ba4;
    &.outline {
      border-color: #f87ba4;
      color: #f87ba4;
    }
  }
  &.violet {
    background-color: #c59bfb;
    @apply text-swarm-black;
    &.outline {
      border-color: #c59bfb;
      color: #c59bfb;
    }
  }
  &.purple {
    background-color: #6e3666;
    &.outline {
      border-color: #6e3666;
      color: #6e3666;
    }
  }
  &.turquoise {
    background-color: #73c8dd;
    &.outline {
      border-color: #73c8dd;
      color: #73c8dd;
    }
  }
  &.gray {
    @apply bg-swarm-gray-700;
    &.outline {
      @apply border-swarm-gray-700 text-swarm-gray-700;
    }
  }
  .image {
    @apply h-full rounded-full object-cover bg-swarm-white;
  }

  &.outline {
    @apply bg-white border-2;
  }
}
