.accountSettings {
  @apply flex gap-4 pb-2;
}

.formWrapper {
  @apply flex-1;
}

.editTeamName {
  @apply flex gap-4 mb-2;
  &Field {
    @apply flex-1;
  }
}

.errorMessage {
  @apply mt-0;
}

.footer {
  @apply flex justify-end bg-swarm-gray-50 py-4 px-4 -m-4 mt-2 border-t border-swarm-gray-200;
}

.saveButton {
  @apply text-white bg-swarm-gray-800;
}

.settingsActionBoxWrapper {
  @apply flex gap-4 justify-between items-center;
}

.settingsActionBoxInfo {
  @apply text-swarm-gray-700;
}

.editIcon {
  @apply absolute -bottom-1 -right-1 p-2 bg-swarm-gray-300 rounded-full;
}

.fileUpload {
  @apply relative cursor-pointer h-24;
  &:hover {
    .editIcon {
      @apply bg-swarm-gray-200;
    }
  }
}

.linkedinSection {
  @apply mb-4;
}
