.textarea {
  @apply w-full
    text-swarm-black
    placeholder-swarm-gray-400
    focus:placeholder-swarm-gray-700 focus:hover:placeholder-swarm-gray-700 hover:placeholder-swarm-gray-500 ring-0 focus:ring-0;
}

.invalid {
  @apply border-swarm-red hover:border-swarm-red;
}

.textareaLimit {
  @apply text-right block mb-4;
}
