.container {
  @apply bg-swarm-white pt-4;
}

.header {
  @apply flex gap-2 items-center uppercase text-swarm-gray-700 font-semibold cursor-pointer py-1 px-3 rounded border border-transparent transition-all;
  font-size: 10px;
  &:hover {
    @apply bg-swarm-gray-50 border-swarm-gray-200;
  }
}

.favHeaderChevron {
  @apply rotate-0 transition-transform;
  &.expanded {
    @apply rotate-90;
  }
}

.favLists {
  @apply flex flex-col gap-1 mt-1;
}

.noFavLists {
  @apply text-swarm-gray-700 text-sm flex gap-2 border border-swarm-gray-400 border-dashed p-2 items-center cursor-default;
}

.listItem {
  @apply flex gap-4 py-1 items-start;
}

.favListIcon {
  @apply size-6 shrink-0;
}
