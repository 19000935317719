.statusSwitch {
  /* exact values, because we need to neutralize 1 additional px both sides to avoid double border */
  margin-left: -9px;
  margin-right: -9px;
}

.statusSwitchButton {
  @apply cursor-pointer flex justify-between gap-1.5 items-center px-2 w-full whitespace-nowrap;
  svg {
    @apply shrink-0;
  }
  &Open {
    svg {
      @apply text-black;
    }
  }
}
