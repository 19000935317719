.requestIntroButton {
  @apply mt-4 transition-all;
  &.inTable {
    @apply mt-0;
  }
}

.requestIntroModal {
  width: 640px;
}

.requestIntroModal {
  @apply font-light text-swarm-gray-700;
  width: 640px;
}

.header {
  @apply text-2xl font-bold text-swarm-black;
}

.divider {
  @apply -mx-8 mb-4 mt-6;
}

.email {
  @apply underline;
}

.hiddenSection {
  @apply hidden;
}
