.contentContainer {
  @apply max-w-xs;
}

.unassignIconContainer {
  @apply absolute w-full text-center h-full -right-full top-0 pt-1 opacity-0 transition-all duration-300 bg-inherit;
}

.unassignIcon {
  @apply cursor-pointer w-4 text-center mx-auto;
}

.tag {
  @apply py-0.5 px-1 text-sm h-6 font-light border justify-center relative overflow-hidden inline-flex items-center;
  border-radius: 3px;
  &:hover {
    .unassignIconContainer {
      @apply opacity-100 right-0;
    }
  }
  &gray {
    @apply bg-swarm-gray-200;
    border-color: #cdcccb;
    color: darken(#cdcccb, 30%);
  }
  &blue {
    @apply bg-swarm-blue-light;
    border-color: #9babec;
    color: darken(#9babec, 30%);
  }
  &turquoise {
    background-color: #e4f4fd;
    border-color: #98bdd2;
    color: darken(#98bdd2, 30%);
  }
  &green {
    @apply bg-swarm-green-light;
    border-color: #98d2b6;
    color: darken(#98d2b6, 30%);
  }
  &yellow {
    @apply bg-swarm-yellow-pale;
    border-color: #ebc05a;
    color: darken(#ebc05a, 30%);
  }
  &orange {
    background-color: #ffdeb8;
    border-color: #e1a968;
    color: darken(#e1a968, 30%);
  }
  &peach {
    background-color: #ffd2cc;
    border-color: #ff9a8f;
    color: darken(#ff9a8f, 30%);
  }
  &pink {
    background-color: #ffddf2;
    border-color: #f797d2;
    color: darken(#f797d2, 30%);
  }
  &violet {
    background-color: #dfd8fd;
    border-color: #b8acf6;
    color: darken(#b8acf6, 30%);
  }
}
