.item {
  @apply rounded hover:bg-swarm-gray-100 my-0.5 p-2 flex items-center w-full cursor-pointer text-base h-10;
}

.checkbox {
  @apply cursor-pointer mr-2;
}

.count {
  @apply ml-auto text-sm text-swarm-gray-500 min-w-8 text-right;
}

.label {
  @apply whitespace-nowrap overflow-hidden mr-2;
}

.userDecorator {
  @apply flex gap-2;
}

.strengthDecorator {
  @apply -ml-1;
}

.crmDecorator {
  @apply flex items-center gap-1;
}
