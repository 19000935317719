.suggestionsIndicator {
  @apply mx-1
   flex
   shrink-0;
}

.suggestionIcon {
  cursor: help;
  text-align: center;
  @apply h-3 w-3 items-center;
  svg {
    margin: auto;
  }
}

.suggestionBubbleHeader {
  @apply flex justify-center py-1;
  h3 {
    @apply flex items-center text-xs font-bold;
    text-transform: uppercase;
    &.headerGreen {
      @apply text-swarm-green;
    }
    &.headerBlue {
      @apply text-swarm-blue-onblack;
    }
    .suggestionIcon {
      cursor: default;
      @apply mr-2;
    }
  }
}

.suggestionBubbleContent {
  @apply px-1 pt-1;
}

.SuggestedRoleRow {
  @apply mb-1 flex justify-center;
}

.linkToPipeline {
  @apply mr-1 capitalize;
  &:hover {
    @apply underline cursor-pointer;
  }
}
