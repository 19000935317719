.wrapper {
  @apply my-6;
}

.header {
  @apply flex items-center gap-2 mb-4 text-xl font-semibold text-swarm-black;
}

.content {
  @apply px-8 py-6 bg-swarm-gray-200 rounded;
}

.videoWrapper {
  @apply relative bg-swarm-gray-200 h-0;
  padding-bottom: 56.25%;
}

.iframe {
  @apply absolute top-0 left-0 w-full h-full rounded;
}
