.wrapper {
  @apply flex flex-col;
}

.selectWrapper {
  @apply relative
    inline-block
    w-full;
}

.select {
  @apply h-10
    w-full
    cursor-pointer
    rounded border-swarm-gray-400
    bg-none
    py-2.5
    pl-3
    leading-5;
}

.select:focus {
  @apply rounded
    border-none
    ring-2 ring-swarm-blue;
}

.selectUnselected {
  @apply text-swarm-gray-500;
}

.selectError {
  @apply border-swarm-red hover:border-swarm-red focus:border-none;
}

.selectIcon {
  @apply pointer-events-none
    absolute inset-y-0 right-1
    mr-4
    h-full;
}

.selectIcon > polyline {
  @apply stroke-swarm-gray-500;
}

.select:hover ~ .selectIcon > polyline {
  @apply stroke-swarm-gray-700;
}

.selectError ~ .selectIcon > polyline,
.selectError:hover ~ .selectIcon > polyline {
  @apply stroke-swarm-red;
}

.select:focus ~ .selectIcon > polyline {
  @apply stroke-swarm-gray-700
  py-0;
}

.selectInputMessage {
  @apply mt-2;
}
