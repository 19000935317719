:root {
  --display-white-border: none;
}

.button {
  outline: none;

  @apply relative overflow-hidden;
  @apply shrink-0; // overflow hidden causing button to shrink, so we want to prevent it
  @apply flex flex-nowrap items-center justify-center gap-2;
  @apply font-semibold text-swarm-black;
  @apply rounded;
  @apply transition duration-200 ease-in-out;
  @apply border border-transparent;
  // we need background-clip on padding-box to not render background under transparent border
  background-clip: padding-box;

  //inner white border
  &:before {
    display: #{var(--display-white-border)};
    @apply rounded;
    border: 1px solid #fff;
    outline: 1px solid #fff;
    @apply absolute inset-0;
    @apply pointer-events-none;
    content: '';
  }

  &Outline,
  &:focus {
    --display-white-border: block;
  }

  &TitleCase {
    @apply capitalize;
  }

  &FullWidth {
    @apply w-full;
  }

  &Disabled.button {
    @apply cursor-not-allowed;
  }

  &Loading.button {
    @apply cursor-progress;
  }

  &ReverseOrder {
    @apply flex-row-reverse;
    .spinner {
      order: -1;
    }
  }

  &Rounded {
    @apply rounded-full;
  }

  &PositionInGroup {
    &First {
      @apply rounded-none;
      @apply rounded-l;
    }
    &Middle {
      @apply rounded-none;
    }
    &Last {
      @apply rounded-none;
      @apply rounded-r;
    }
  }

  &Size {
    &XSmall {
      @apply h-7 px-2 text-xs;
      &.buttonIconOnly {
        @apply w-7;
      }

      .spinner {
        @apply h-3 w-3;
      }
    }

    &Small {
      @apply h-8 px-2.5 text-sm;
      &.buttonIconOnly {
        @apply w-8;
      }

      .spinner {
        @apply h-4 w-4;
      }
    }

    &Medium {
      @apply h-10 px-3.5 text-base;
      &.buttonIconOnly {
        @apply w-10;
      }

      .spinner {
        @apply h-5 w-5;
      }
    }

    &Large {
      @apply h-12 px-5 text-lg;
      &.buttonIconOnly {
        @apply w-12;
      }

      .spinner {
        @apply h-6 w-6;
      }
    }

    &XLarge {
      @apply h-14 px-6 text-xl;
      &.buttonIconOnly {
        @apply w-14;
      }

      .spinner {
        @apply h-7 w-7;
      }
    }
  }

  // here we have .button.buttonVariant for rise css specificity (problem with tailwind base styles)
  &.button {
    &Variant {
      &Primary {
        @apply bg-swarm-gradient;

        &.buttonOutline,
        &:focus {
          @apply border-swarm-black;
        }

        &:not(.buttonDisabled) {
          &:hover,
          &:focus,
          &:active {
            filter: contrast(1.05) saturate(1.05) brightness(1.1);
          }
        }

        &.buttonDisabled {
          @apply bg-swarm-gray-400 bg-none text-white hover:bg-none;
        }
      }

      &Secondary {
        @apply bg-swarm-gray-200 hover:bg-swarm-gray-100 focus:bg-swarm-gray-200;
        &.buttonOutline,
        &:focus {
          @apply border-swarm-gray-500;
        }

        &.buttonDisabled {
          .blend {
            @apply opacity-60;
          }
        }
      }

      &Tertiary {
        @apply bg-none hover:bg-swarm-gray-100 focus:bg-white;
        &.buttonOutline,
        &:focus {
          @apply border-swarm-gray-500;
        }

        &.buttonDisabled {
          .blend {
            @apply opacity-60;
          }
        }
      }

      &Black {
        @apply text-white;
        @apply bg-swarm-black hover:bg-swarm-overlay focus:bg-swarm-black;
        &.buttonOutline,
        &:focus {
          @apply border-swarm-gray-900;
        }

        &.buttonDisabled {
          .blend {
            @apply opacity-70;
          }
        }
      }

      &Red {
        /*
         * css declarations instead of tailwind - currently sass+tailwind with
         * custom values in [] brackets break the build
         */
        background-color: #fce4e1;
        color: #cf2a13;
        &:hover {
          background-color: #ffeeeb;
        }
        &.buttonOutline,
        &:focus {
          border: 1px solid #e79489;
        }

        &.buttonDisabled {
          .blend {
            @apply opacity-60;
          }
        }
      }

      &Green {
        /*
         * css declarations instead of tailwind - currently sass+tailwind with
         * custom values in [] brackets break the build
         */
        background-color: #e2f6ec;
        color: #327d4f;
        &:hover {
          background-color: #e9fcf3;
        }
        &.buttonOutline,
        &:focus {
          border: 1px solid #98d2b6;
        }

        &.buttonDisabled {
          .blend {
            @apply opacity-50;
          }
        }
      }
    }
  }

  &IconOnly {
    @apply px-0;
    .text {
      @apply hidden;
    }

    &.buttonLoading {
      .icon {
        @apply hidden;
      }
    }
  }
}

.text {
  @apply truncate;
}

.spinner {
}

.icon {
}

.blend {
  @apply opacity-0 transition duration-500 ease-in-out;
  @apply absolute inset-0 z-10 bg-white;
  @apply pointer-events-none;
}
