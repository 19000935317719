.dropdownItemIcon {
  @apply w-5 mr-2 flex justify-center;
}

.dropdown {
  min-width: 210px;
  @apply relative
    my-2
    z-10
    max-h-96
    w-full
    max-w-sm
    overflow-auto
    rounded
    border border-swarm-gray-400
    bg-white
    shadow-swarm-lg-soft;
  &Narrow {
    min-width: 134px;
    max-width: 240px;
  }
}

.dropdownItems {
  @apply p-1;
}

.dropdownTrigger {
  cursor: pointer;
}

.dropdownItem {
  @apply list-none
    rounded
    hover:bg-swarm-gray-100
    mb-0.5;
  &NoHover {
    @apply hover:bg-transparent;
  }
  &Custom {
    .dropdownItemButton {
      @apply p-2;
    }
  }
}

.disabled {
  @apply opacity-50;
}

.dropdownItemCheckboxButton,
.dropdownItemRadioButton {
  @apply flex
    w-full
    items-center
    p-2;

  &Checked {
    @apply rounded bg-swarm-gray-100;
  }
}

.dropdownItemButton,
.dropdownItemA {
  @apply flex w-full
      items-center
      rounded
      border-swarm-gray-300
      p-1;

  &WithInfo {
    @apply w-full;

    .buttonContent {
      @apply flex w-full
          items-center
          rounded
          border-swarm-gray-300
          p-1
          text-left
          hover:bg-swarm-gray-100;
    }
    .additionalInfo {
      @apply flex w-full
        p-1
        text-xs text-swarm-gray-700
        text-left;
    }
  }
}

.danger {
  @apply text-swarm-red;
}

.success {
  @apply text-swarm-green-darken;
}

.dropdownItemsGroupTitle {
  font-size: 10px;
  @apply pointer-events-none px-2
    pt-2 pb-1
    font-medium
    uppercase
    text-swarm-gray-700;
}

.dropdownHeader {
  @apply sticky
    top-0
    z-10
    flex
    w-full
    items-center
    justify-between
    border-b
    border-swarm-gray-300;
}

.dropdownFooter {
  @apply sticky
    bottom-0
    p-1
    flex
    w-full
    items-center
    justify-between
    border-t
    border-swarm-gray-300
    bg-white;
}

.badge {
  @apply ml-3 text-swarm-gray-500 text-center;
}
