.listWrapper {
  @apply flex flex-col gap-1 mt-4 mb-2;
}

.itemWrapper {
  @apply text-xs py-3 pb-2 px-2.5 bg-white border border-swarm-gray-400 rounded w-full;
}

.heading {
  @apply text-xs font-bold flex gap-2 items-center;
}

.loadingBar {
  @apply mt-3 mb-1 border border-swarm-gray-300 rounded;
}

.description {
  @apply text-swarm-gray-700 mt-3 mb-1 text-xs;
}

.sourceIcon {
  @apply shrink-0;
}
