.invitation {
  @apply shadow-swarm-lg-soft mb-3;
}

.invitationContent {
  @apply flex gap-4 items-center pb-4;
  .companyName {
    @apply font-bold;
  }
}

.invitationButtons {
  @apply flex gap-2 items-center;
  .invitationButton {
    @apply grow basis-0;
  }
}
